import { getToken } from '@/utils/auth'
export default vm => {
    return {
        submitBtn: false,
        emptyBtn: false,
        column: [
            {
                label: '图纸名称',
                prop: 'name',
                value: vm?.currentRow?.archives && vm?.currentRow?.archives?.fireName + '图纸',
                span: 24
            },
            {
                label: '制图单位',
                prop: 'planOrg',
                value: vm?.currentRow?.archives && vm?.currentRow?.archives?.doOrg,
                span: 24
            },
            {
                label: '制图人',
                prop: 'planPerson',
                value: vm?.formData?.userName,
                span: 24
            },
            {
                label: '制图时间',
                prop: 'time',
                type: 'datetime',
                value: vm.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
                format: 'yyyy年MM月dd日 HH时mm分',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                editable: false,
                span: 24
            },
            {
                label: '起火单位',
                prop: 'fireOrg',
                value: vm?.currentRow?.archives && vm?.currentRow?.archives?.fireName,
                span: 24
            },
            {
                label: '起火时间',
                prop: 'fireTime',
                type: 'datetime',
                format: 'yyyy年MM月dd日 HH时mm分',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                value: (vm?.currentRow?.archives && vm?.currentRow?.archives?.fireTime) || vm.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
                editable: false,
                span: 24
            },
            {
                label: '起火地址',
                prop: 'address',
                value: vm?.currentRow?.archives && vm?.currentRow?.archives?.address,
                editable: false,
                span: 24
            },
            {
                label: '审图人',
                prop: 'applyPlanPerson',
                value: vm?.defaultPersonName,
                span: 24
            },
            {
                label: '录音',
                prop: 'studioUrlList',
                type: 'upload',
                loadText: '附件上传中，请稍等',
                span: 24,
                labelTip: '上传录音，推荐用60秒',
                action: '/common/upload?isDic=true',
                accept: 'audio/*',
                headers: {
                    Authorization: 'Bearer ' + getToken()
                },
                propsHttp: {
                    res: 'data'
                },
                props: {
                    label: 'name',
                    value: 'url'
                },
            }
        ]
    }
}