import { getToken } from '@/utils/auth'
import { DIRECTION_LIST } from '@/const/archives/video'
export default vm => {
    const navigatorArr = window.navigator.userAgent.split(';')
    const arr = navigatorArr[2] ? navigatorArr[2].split('/') : []
    return {
        submitBtn: false,
        emptyBtn: false,
        column: [
            {
                label: '拍摄地点',
                prop: 'description',
                value: (vm?.currentRow?.archives && vm?.currentRow?.archives?.address) || '',
                span: 24
            },
            {
                label: '视频分类',
                prop: 'category',
                span: 24,
                type: 'select',
                dicUrl: `/system/dict/data/type/video_category?isDic=true`,
                props: {
                    label: 'dictLabel',
                    value: 'dictValue'
                },
                dicHeaders: {
                    Authorization: 'Bearer ' + getToken()
                },
            },
            {
                label: '拍摄时间',
                prop: 'time',
                value: vm.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
                format: 'yyyy年MM月dd日 HH时mm分',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                type: 'datetime',
                editable: false,
                span: 24
            },
            {
                label: '拍摄方向',
                prop: 'direction',
                type: 'select',
                dicData: DIRECTION_LIST,
                value: '由东向西',
                span: 24
            },
            {
                label: '拍摄人',
                prop: 'person',
                value: vm.defaultPersonName,
                span: 24
            },
            {
                label: '证据来源',
                prop: 'resource',
                span: 24,
                type: 'select',
                dicUrl: `/system/dict/data/type/resource_type?isDic=true`,
                props: {
                    label: 'dictLabel',
                    value: 'dictValue'
                },
                dicHeaders: {
                    Authorization: 'Bearer ' + getToken()
                },
            },
            {
                label: '拍摄设备',
                prop: 'device',
                span: 24,
                value: arr[1]
            },
            {
                label: '标签',
                prop: 'tag',
                type: 'select',
                dicUrl: `/system/dict/data/type/tag_type?isDic=true`,
                props: {
                    label: 'dictLabel',
                    value: 'dictValue'
                },
                dicHeaders: {
                    Authorization: 'Bearer ' + getToken()
                },
                span: 24
            }
        ]
    }
}