<template>
    <div>
        <div v-loading="loading" class="add_wrap">
            <header class="header">
                <div class="title"><span>{{ pageName }}</span></div>
                <!-- <div class="info">
                    <div class="userName"> 上传人：{{ formData.userName || '--' }}</div>
                    <div class="userName"> 上传日期：<span>{{ parseTime(formData.createTime, '{y}年{m}月{d}日') || '--' }}</span>
                    </div>
                </div> -->
                <div class="upload-box">
                    <div>
                        <!-- <el-radio v-model="formData.isNoGroup" :disabled="true" :label="true"
                            @change="formData.fileList = []">单个上传</el-radio> -->
                    </div>
                    <div>
                        <el-upload ref="elupload" :action="uploadUrl" :show-file-list="false" :file-list="formData.fileList"
                            :accept="uploadAccept" :headers="headers" multiple :before-upload="handleBeforeUpload"
                            :on-preview="handlePictureCardPreview" :on-success="handleOnImgSuccess"
                            :on-remove="handleOnRemove">
                            <el-button icon="el-icon-picture-outline" size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </div>
                </div>
            </header>
            <main class="main">
                <div class="container" v-if="formData.fileList && formData.fileList.length">
                    <article class="item" v-for="(file, index) in formData.fileList" :key="index">
                        <div class="wrap">
                            <div class="head">
                                <div class="file-preview" @click="handlePictureCardPreview(file)">
                                    <video v-if="getMainFileType(file) == 'video'" :src="file.url" width="100%"
                                        height="100%"></video>
                                    <template v-else-if="getMainFileType(file) == 'pdf'">
                                        <iframe :src="file.url" frameborder="0"></iframe>
                                    </template>

                                    <img v-else-if="getMainFileType(file) == 'image'" :src="file.url" width="100%"
                                        height="100%" />

                                    <template v-else-if="file.url && file.url.slice(0, 4) != 'blob'">
                                        <div style="height: 100%;" flex="main:center cross:center">无法识别的文件类型</div>
                                    </template>
                                    <div class="mask"></div>
                                </div>
                                <div class="close">
                                    <i class="el-icon-close" @click="handleOnRemoveBefore(file, index)"></i>
                                </div>
                            </div>
                            <avue-form v-model="file.form" :option="calcOption" :upload-before="uploadBefore" />
                        </div>
                    </article>
                </div>
                <el-empty v-else :image-size="400" description="暂无数据"></el-empty>
            </main>
            <footer class="footer">
                <FooterBtn :pdfDownload="pdfDownload" :submitDis="false" @submit="submitForm" @cancel="handleCancel" />
            </footer>
        </div>

        <el-image v-show="false" ref="elImage" :preview-src-list="srcList" />
        <PdfDialog v-model="pdfDialog.show" :pdf-url="pdfDialog.url" />
    </div>
</template>

<script>
import DataFlow from '@/mixins/DataFlow'
import PdfDialog from '@/components/PdfDialog/PdfDialog.vue';
import FooterBtn from '@/components/FooterBtn/index.vue'

export default {
    mixins: [DataFlow],
    components: {
        PdfDialog, FooterBtn
    },
    provide() {
        return {
            videoSrcObj: this.videoSrcObj
        }
    },
    data() {
        return {
            srcList: [],
            videoSrcObj: {},
            pdfDialog: {
                show: false,
                url: ''
            },
        }
    },
    computed: {
        uploadUrl() {
            return this.baseUrl + '/common/upload'
        },
        uploadAccept() {
            let fileType = ''
            switch (parseInt(this.currentRow.dictId)) {
                case 5: // 现场专业图
                    fileType = 'image/*'
                    break;
                case 6: // 现场草图
                    fileType = 'image/*'
                    break;
                case 11: // 证据材料
                    fileType = 'image/*, video/*, .pdf'
                    break;
                case 12: // 火灾现场照片
                    fileType = 'image/*'
                    break;
                case 13: // 视频管理
                    fileType = 'video/*'
                    break;
            }
            return fileType
        },
        getMainFileType() {
            return (file) => {
                if (file.type == 'application/pdf' || file.type == 'pdf') return 'pdf'
                if (file.type && file.type.indexOf('/') != '-1') return file.type.split('/')[0]
                if (file.url && file.url.indexOf('.') != '-1') {
                    const extension = file.url.split('.').pop().toLowerCase();
                    const fileTypeMapping = {
                        jpg: 'image',
                        jpeg: 'image',
                        png: 'image',
                        gif: 'image',
                        mp4: 'video',
                        mov: 'video',
                        avi: 'video',
                        wmv: 'video',
                    };
                    const fileType = fileTypeMapping[extension] || '未知类型';
                    return fileType;
                }
                if (!file.type) return '未知类型'
            }
        },
        photoFormOption() {
            return require(`@/option/photoFormOption`).default(this)
        },
        videoFormOption() {
            return require(`@/option/videoFormOption`).default(this)
        },
        planOption() {
            return require(`@/option/planOption`).default(this)
        },
        materialsOption() {
            return require(`@/option/materialsOption`).default(this)
        },
        calcOption() {
            const obj = {
                5: this.planOption,
                6: this.planOption,
                11: this.materialsOption,
                12: this.photoFormOption,
                13: this.videoFormOption
            }
            return obj[this.currentRow.dictId] || this.materialsOption
        },
        host() {
            return location.origin
        },
        isImg() {
            return (url) => {
                if (!url) return ''
                return ['jpg', 'png', 'jpeg'].includes(url.substring(url.lastIndexOf('.') + 1))
            }
        },
    },
    methods: {
        afterHandleData(formData) {
            if (!formData?.fileList && !formData?.fileList?.length) return

            let fileList = formData.fileList
            let keys = this.calcOption.column.map(item => item.prop)

            fileList.forEach(item => {
                item.form = {}
                keys.forEach(itemKey => {
                    item.form[itemKey] = item[itemKey]
                })
            })

            formData.fileList = [...fileList]
        },
        handleBeforeUpload(file) {
            console.log(file);
            const isLt50M = file.size / 1024 / 1024 < 50

            if (!isLt50M) {
                this.$message.error('上传的文件大小超出限制的文件大小！允许的文件最大大小是：50MB！')
            }
            let fileTypeFirst;

            if (file.type == '') {
                let fileStrArr = file.name.split('.')
                fileTypeFirst = fileStrArr[fileStrArr.length - 1]
            } else if (file.type == 'application/pdf') {
                fileTypeFirst = 'application/pdf'
            } else {
                fileTypeFirst = file.type && file.type.split('/')[0]
            }

            let fileTypes = []
            let msg = ''
            switch (parseInt(this.params.dictId)) {
                case 5: // 现场专业图
                    fileTypes = ['image', 'application/pdf', 'dwg']
                    break;
                case 6: // 现场草图
                    fileTypes = ['image']
                    break;
                case 11: // 证据材料
                    fileTypes = ['image', 'application/pdf', 'video']
                    break;
                case 12: // 火灾现场照片
                    fileTypes = ['image']
                    break;
                case 13: // 视频管理
                    fileTypes = ['video']
                    break;
            }

            const isFileType = fileTypes.includes(fileTypeFirst)

            fileTypes.forEach((item, index) => {
                if (index == 0) {
                    msg += ` ${item.toLocaleUpperCase()} `
                } else {
                    msg += `| ${item.toLocaleUpperCase()} `
                }
            })

            if (!isFileType) {
                this.$message.error(`上传附件只能是${msg}格式!`);
            }

            if (this.isVideo(file.name)) {
                this.getVideoBase64(file)
                file.videoSrcObj = this.videoSrcObj
            }

            return isLt50M && isFileType
        },
        // 音频上传 before
        uploadBefore(file, done, loading) {

            const isLt50M = file.size / 1024 / 1024 < 50

            if (!isLt50M) {
                this.$message.error('上传的文件大小超出限制的文件大小！允许的文件最大大小是：50MB！')
                return loading()
            }
            if (file.type == '') {
                return
            }

            const isFileType = file.type.split('/')[0] == 'audio'
            if (!isFileType) {
                this.$message.error(`上传附件只能是音频格式!`);
                return loading()
            }
            done()
        },
        uploadAfter(res, done) {
            done()
        },
        uploadError(error, column) {

        },
        uploadDelete(file, column, index) {
            console.log(file);
            console.log(column);
            console.log(this.formData.fileList);
            // const itemIndex = this.formData.fileList[index].form.studioUrlList.findIndex(item => item === file.url);
            // if (itemIndex !== -1) {
            //     this.formData.fileList[index].form.studioUrlList.splice(itemIndex, 1);
            // }
        },
        getVideoBase64(file) {
            const that = this
            const blob = new Blob([file]) // 文件转化成二进制文件
            const url = URL.createObjectURL(blob) // 转化成url
            return new Promise(function (resolve, reject) {
                let dataURL = ''
                const video = document.createElement('video')
                video.setAttribute('crossOrigin', 'anonymous') // 处理跨域
                video.setAttribute('src', url)
                video.setAttribute('width', 400)
                video.setAttribute('height', 240)
                video.setAttribute('preload', 'auto')
                video.addEventListener('loadeddata', () => {
                    const canvas = document.createElement('canvas')
                    const width = video.width // canvas的尺寸和图片一样
                    const height = video.height
                    canvas.width = width
                    canvas.height = height
                    canvas.getContext('2d').drawImage(video, 0, 0, width, height) // 绘制canvas
                    dataURL = canvas.toDataURL('image/jpeg') // 转换为base64
                    that.videoSrcObj[file.name] = dataURL
                    that.$refs.elupload.$forceUpdate()
                })
            })
        },
        handleClickRemove(file) {
            this.$refs.elupload.handleRemove(file)
        },
        handleOnRemoveBefore(file, index) {
            let fileList = this.deepClone(this.formData.fileList)
            fileList.splice(index, 1)
            this.handleOnRemove(file, fileList)
        },
        handleOnRemove(file, fileList) {
            let newFileList = fileList
            if (this.currentRow.dictId == 12) {
                newFileList = fileList.map((item, index) => {
                    return {
                        ...item,
                        form: {
                            ...item.form,
                            serial: `编号（ ${index + 1}  ）`
                        }
                    }
                })
            }

            this.formData.fileList = [...newFileList]
        },
        // 调用el -upload 里子组件的方法，自动触发 handleOnRemove 方法
        handleReOnRemove(file) {
            this.$refs.elupload.$children[0].$emit('remove', file)
        },
        handlePictureCardPreview({ url, type }) {
            if (this.isPdf(url)) {
                this.toPdf(url)
                return
            }
            this.srcList = [{ url, type }]

            this.$refs.elImage.showViewer = true
        },
        handleOnImgSuccess(res, file) {
            if (this.getMainFileType(file.raw) == 'video') {
                this.formData.fileList.push({
                    ...res.data,
                    type: file.raw.type,
                    duration: res?.data?.duration || 0
                })
            } else {
                this.formData.fileList.push({
                    ...res.data,
                    type: file.raw.type
                })
            }
        },
        handleError(res) {
            this.$message.error(res)
            this.loading = false
        },
        submitFormBefore() {
            if (this.formData.createTime) {
                delete this.formData.createTime
            }
            this.formData.fileList.forEach(imgFile => {
                if (imgFile.form && imgFile.form.studioUrlList) {
                    if (this.currentRow.dictId == 12) {
                        imgFile.studioUrlList = imgFile.form.studioUrlList.join(',')
                    }
                }
            })

            this.formData.fileList = this.formData.fileList.map(item => {
                return {
                    ...item,
                    ...item.form,
                }
            })
        },
        toPdf(url) {
            this.pdfDialog.show = true
            this.pdfDialog.url = url
        },
        initForm() {
            let initForm = {
                remark: '',
                fileList: [],
                userName: this.$route.query.nickName || '群众',
                createTime: new Date(),
                isNoGroup: true
            }
            return initForm
        }
    }
}
</script>

<style lang="scss" scoped>
.add_wrap {
    width: 1180px;
    margin: 0 auto;
    height: 100vh;

    .header {
        width: 100%;
        padding-bottom: 32px;

        .title {
            padding: 13px 24px;
            border-bottom: 1px solid rgb(0 144 225 / 8%);
            color: #4E5969;
            font-size: 16px;
            font-weight: bold;
        }

        .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
            color: #61696D;
            font-size: 14px;
        }

        .upload-box {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 24px 24px 0;
            color: #86909C;
            font-size: 16px;
        }
    }

    .main {
        box-sizing: border-box;
        padding: 0 24px 0;
        height: calc(100vh - 136px - 72px);
        overflow: hidden;

        .container {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            margin-right: -24px; // gap 导致的 多出来的距离
            overflow-y: auto;

            .item {
                flex: 0 0 calc(50% - 24px);
                background: #F8FDFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid rgba(0, 144, 225, 0.16);
                box-sizing: border-box;

                .wrap {
                    padding: 24px;

                    .head {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 36px;

                        .close {
                            .el-icon-close {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .file-preview {
                    position: relative;
                    width: 80px;
                    height: 80px;
                    overflow: hidden;
                    border-radius: 4px;

                    iframe {
                        width: calc(100% + 18px);
                        height: calc(100% + 18px);
                    }

                    .mask {
                        cursor: pointer;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                    }
                }
            }
        }
    }

    &>.footer {
        display: flex;
        justify-content: flex-end;
        padding: 16px 24px;
    }
}

::v-deep .main {
    .avue-group {
        .el-collapse {
            background-color: transparent;
            border: none;

            .el-collapse-item__wrap {
                background-color: transparent;
                border: none;
            }
        }
    }


    .el-form-item__label {
        color: #082A3D;
        font-size: 16px;
    }

    .el-form-item__content {

        textarea,
        input {
            background-color: #EBF7FD;
            border: 1px solid rgba(0, 144, 225, 0.16);
            color: #0090E1;
            font-weight: bold;
        }

    }
}
</style>
