<template>
    <el-dialog title="预览" top="5vh" custom-class="file-dialog" :visible.sync="visible" width="80%"
        :before-close="handleClose">
        <iframe ref="fileIframe" style="height: 80vh; width: 100%" :src="`${host}/pdf/web/viewer.html?file=${pdfUrl}`"
            frameborder="0"></iframe>
    </el-dialog>
</template>

<script>
export default {
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        pdfUrl: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            pdfDialog: {
                show: false,
                url: ''
            }
        }
    },
    computed: {
        host() {
            return location.origin
        }
    },
    methods: {
        handleClose() {
            this.$emit('change', false)
        }
    }
}
</script>
<style scoped></style>