import { getToken } from '@/utils/auth'
import { DIRECTION_LIST } from '@/const/archives/video'
export default vm => {
    return {
        submitBtn: false,
        emptyBtn: false,
        column: [
            {
                label: '编号',
                prop: 'serial',
                value: `编号（ ${vm.formData?.fileList?.length}  ）`,
                span: 24
            },
            {
                label: '说明',
                prop: 'description',
                value: vm?.currentRow?.archives && vm?.currentRow?.archives?.address,
                span: 24
            },
            {
                label: '拍摄时间',
                prop: 'time',
                value: vm.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
                format: 'yyyy年MM月dd日 HH时mm分',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                type: 'datetime',
                editable: false,
                span: 24
            },
            {
                label: '拍摄方向',
                prop: 'direction',
                type: 'select',
                dicData: DIRECTION_LIST,
                value: '由东向西',
                span: 24
            },
            {
                label: '拍摄人',
                prop: 'person',
                value: vm.defaultPersonName,
                span: 24
            },
            {
                label: '审核人',
                prop: 'applyPerson',
                value: vm.defaultPersonName,
                span: 24
            },
            {
                label: '制作人',
                prop: 'creator',
                value: vm.defaultPersonName,
                span: 24
            },
            {
                label: '制作时间',
                prop: 'editTime',
                value: vm.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
                format: 'yyyy年MM月dd日',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                type: 'date',
                editable: false,
                span: 24
            },
            {
                label: '录音',
                prop: 'studioUrlList',
                type: 'upload',
                loadText: '附件上传中，请稍等',
                span: 24,
                labelTip: '上传录音，推荐用60秒',
                action: '/common/upload?isDic=true',
                accept: 'audio/*',
                headers: {
                    Authorization: 'Bearer ' + getToken()
                },
                props: {
                    label: 'name',
                    value: 'url'
                },
                propsHttp: {
                    res: 'data'
                }
            }
        ]
    }
}