export default vm => {
    return {
        submitBtn: false,
        emptyBtn: false,
        column: [
            {
                label: '拍摄时间',
                prop: 'time',
                format: 'yyyy年MM月dd日 HH时mm分',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                value: (vm?.currentRow?.archives && vm?.currentRow?.archives?.fireTime) || vm.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
                type: 'datetime',
                editable: false,
                span: 24
            },
            {
                label: '拍摄人',
                prop: 'person',
                value: vm.defaultPersonName,
                span: 24
            },
            {
                label: '备注',
                type: 'textarea',
                prop: 'remark',
                span: 24
            }
        ]
    }
}